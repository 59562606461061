<template>
  <div class="footer-container row-around">
    <div class="footer-nav-container">
      <div class="mt-50 logo-container">
        <img src="@/assets/Logo.png" alt="" />
      </div>
      <v-row justify="center">
        <div class="navigation row-between mt-50 mb-50">
          <div>
            <h5>{{ $t("button.navbar[11]") }}</h5>
            <ul>
              <li>
                <router-link to="/">{{ $t("button.navbar[8]") }}</router-link>
              </li>
              <li>
                <router-link to="/mission">{{ $t("button.navbar[3]") }}</router-link>
              </li>
            </ul>
          </div>
          <div>
            <h5>{{ $t("button.navbar[10]") }}</h5>
            <ul>
              <li>
                <router-link to="/products/implants">{{
                  $t("button.navbar[1]")
                }}</router-link>
              </li>
              <li>
                <router-link to="/products/matrice">{{
                  $t("button.navbar[0]")
                }}</router-link>
              </li>
              <li>
                <router-link to="/products/actionneurs-capteurs">{{
                  $t("button.navbar[2]")
                }}</router-link>
              </li>
              <li>
                <router-link to="/logiciel/micecloud">{{
                  $t("button.navbar[6]")
                }}</router-link>
              </li>
            </ul>
          </div>
          <div>
            <h5>Contact</h5>
            <ul>
              <li>
                <router-link to="/contact">{{ $t("button.navbar[7]") }}</router-link>
              </li>
              <li>
                <p>info@micetracking.com</p>
              </li>
              <li>
                <p style="margin-bottom: 5px">40 rue du bignon,</p>
                <p style="margin-bottom: 5px">35500 Cesson Sévigné</p>
                <p style="margin-bottom: 5px">France</p>
              </li>
            </ul>
          </div>
          <div class="social">
            <h5>{{ $t("button.navbar[9]") }}</h5>
            <ul class="row-around">
              <li>
                <a
                  href="https://www.linkedin.com/company/micetracking/?viewAsMember=true"
                  aria-label="LinkedIn"
                >
                  <v-icon style="font-size: 45px">mdi-linkedin</v-icon>
                </a>
              </li>
            </ul>
            <v-select
              v-model="language_selected"
              :items="langs"
              item-value="prefix"
              item-text="name"
              label="Langues"
              @change="setLanguage"
              style="margin-top: 50px; width: 100%"
            ></v-select>
          </div>
        </div>
      </v-row>
      <div class="copyright mb-25">
        <p>&copy; {{ new Date().getFullYear() }} - MiceTracking</p>
        <div>
          <router-link to="/terms">{{ $t("button.navbar[12]") }}</router-link> |
          <router-link to="/privacy">{{ $t("button.navbar[13]") }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      langs: [
        { name: "Français", prefix: "fr" },
        { name: "English", prefix: "en" },
      ],
      language_selected: "",
    };
  },
  methods: {
    setLanguage(event) {
      // Logique pour définir la langue préférée
      this.$i18n.locale = this.language_selected;
      localStorage.setItem("lang preference", this.language_selected);
      // Ici, vous pouvez stocker la préférence de langue de l'utilisateur, par exemple dans localStorage
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-container {
  background-color: var(--grisclair);
}

.footer-nav-container {
  width: 70%;
}

.logo-container {
  width: 300px;

  img {
    width: 100%;
  }
}

.navigation {
  width: 100%;

  div {
    width: 16%;

    ul {
      li {
        margin-top: 20px;

        a {
          font-family: "roboto";
          font-weight: 400;
          font-size: 18px;
          color: var(--noirdoux);
          transition: color 0.3s ease;

          &:hover {
            color: var(--vertfonce);
          }
        }
      }
    }
  }
}

.social {
  text-align: center;

  img {
    width: 40px;
  }
}

.copyright {
  text-align: center;
  border-top: 1px solid;
  margin-top: 40px;

  p {
    margin-top: 10px;
  }
}

@media (max-width: 960px) {
  /* Masquer la liste de navigation normale et afficher le menu burger */
  .row-between {
    flex-direction: column;
    align-items: center;
  }

  .navigation {
    display: block;

    div {
      width: 90%;
      text-align: center;
      margin-top: 20px;

      ul {
        li {
          margin-top: 5px;
        }
      }
    }
  }
}
</style>
