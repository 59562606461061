import {getRESTApiUri} from './get-uri';

export async function switchToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    const url = getRESTApiUri() + `/refresh-token`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                refreshToken: refreshToken,
            })
        });
        if (!response.ok) {
            throw new Error('Failed to refresh token');
        }
        const result = await response.text();
        const data = JSON.parse(result);
        localStorage.setItem('accessToken', data.accessToken);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export async function testToken() {
  if (safeGetItem('accessToken') !== null) {
    const token = localStorage.getItem('accessToken');
    const url = getRESTApiUri() + `/me/${token}`;
    return fetch(url)
      .then(res => res.text())
      .then((result) => {
        let response;
        try {
          response = JSON.parse(result);
        } catch (e) {
          if (result === "Unauthorized") {
            return switchToken();
          } else {
            return false;
          }
        }
        if (response.accessPage === true) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
        return switchToken();
      });
  } else {
    return false;
  }
}

function safeGetItem(key) {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.warn('localStorage inaccessible', e);
    return null;
  }
}

export async function testCode() {
  // Vérifier si l'item 'code' existe dans le localStorage
  if (localStorage.getItem('code') !== null) {
    const code = localStorage.getItem('code');
    const url = getRESTApiUri() + `/check-code-existence/${code}`;
    return fetch(url)
      .then(res => res.text())
      .then((result) => {
        const response = JSON.parse(result);
        if(response.length > 0){
          return true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    // Si l'item 'code' n'existe pas, le créer avec une valeur par défaut
    return false;
  }
};

export function adminTest(){
	const user = JSON.parse(safeGetItem('user'))
	const AccessAdmin = JSON.parse(safeGetItem('accessAdmin'))
	if(user){
		if(user.admin == 'true' && AccessAdmin == true){
			return true;
		}else{
			return false;
		}
	}
}