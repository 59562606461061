import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
import router from './router'

import vuetify from './plugins/vuetify'
import BackEnd from './back-end'
import messages from './lang/messages';

import VueI18n from 'vue-i18n';
import '@mdi/font/css/materialdesignicons.min.css';

Vue.use(VueI18n);
Vue.use(VueMeta);
Vue.prototype.$eventHub = new Vue();
Vue.prototype.$api = new BackEnd();
Vue.config.productionTip = false;
const userLanguage = (navigator.language || navigator.userLanguage).split('-')[0]; // Prend la première partie de la langue (ex: 'en' de 'en-US')

const i18n = new VueI18n({
  locale: userLanguage, // Utilise la langue du navigateur comme langue par défaut
  fallbackLocale: 'en', // Langue de secours
  messages, // Vos messages de traduction
});

router.afterEach((to) => {
  if (window.gtag) {
    window.gtag('event', 'page_view', {
      page_path: to.fullPath,
      page_title: document.title,
    });
  }
});

new Vue({
	router,
	vuetify,
	i18n,
	render: h => h(App)
}).$mount('#app')