<template>
  <div v-if="isVisible" class="popup">
    <div class="popup-inner">
      <h3>Sélectionnez votre langue</h3>
      <v-select
        v-model="language_selected"
        :items="langs"
        item-value="prefix"
        item-text="name"
        label="Langues"
        @change="setLanguage"
      ></v-select>
      <button @click="closePopup">OK</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      langs: [
        { name: "Français", prefix: "fr" },
        { name: "English", prefix: "en" },
      ],
      language_selected: "",
    };
  },
  methods: {
    setLanguage(event) {
      // Logique pour définir la langue préférée
      this.$i18n.locale = this.language_selected;
      localStorage.setItem("lang preference", this.language_selected);
      // Ici, vous pouvez stocker la préférence de langue de l'utilisateur, par exemple dans localStorage
    },
  },
};
</script>

<style scoped>
.popup {
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}
</style>
