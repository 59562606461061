<template>
  <v-app>
    <div class="backgroud-app">
      <router-view :key="$route.fullPath" />
    </div>
  </v-app>
</template>

<script>
import "normalize.css";
import SideBarAdmin from "./components/SideBarAdmin";
import LanguagePopup from "./components/LanguagePopup.vue";
import foot from "@/components/FooterBis.vue";

export default {
  name: "App",
  components: {
    SideBarAdmin,
    LanguagePopup,
    foot,
  },
  data() {
    return {
      isTabHidden: false,
    };
  },
};
</script>

<style>
html {
  font-size: 16px;
}

h1 {
  margin: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.v-application ul {
  padding-left: 0px;
}
/* HTML5 display-reset */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
a {
  text-decoration: none;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  /* couleurs sites */
  --bleu: #173b67;
  --vertfonce: #3cb394;
  --bleuclair: #708ac4;
  --noirdoux: #2d3748;
  --grisclair: #e0e5eb;
  --background: #f2f5f7;
  --white: #ffffff;
}
h1 {
  color: var(--background);
}
h2,
h3,
h4,
h5,
p {
  color: var(--noirdoux);
}

h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 64px;
}
h2 {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 48px;
}

h3 {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 36px;
}

h4 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 28px;
}

h5 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 24px;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: light;
  font-size: 18px;
}

.backgroud-app {
  min-height: 100vh;
  background-color: var(--background);
}
.row-center {
  display: flex;
  justify-content: center;
}

.row-around {
  display: flex;
  justify-content: space-around;
}
.row-between {
  display: flex;
  justify-content: space-between;
}

.column-between {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row-between-xl {
  display: flex;
  justify-content: space-between;
}

.my-app {
  min-height: 0;
}
.padding100 {
  padding-left: 100px;
}

.mt100 {
  margin-top: 100px;
}

.mt30 {
  margin-top: 30px;
}

.mt10 {
  margin-top: 10px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-120 {
  margin-top: 120px;
}

.mb-25 {
  margin-bottom: 25px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-100 {
  margin-bottom: 100px;
}

.ml10 {
  margin-left: 10px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-100 {
  margin-left: 100px;
}

.mr-25 {
  margin-right: 25px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-100 {
  margin-right: 100px;
}

.p-25 {
  padding: 25px;
}
.p-50 {
  padding: 50px;
}
.p-100 {
  padding: 100px;
}

.text-white {
  color: var(--white);
}
.button-contact {
  background-color: var(--vertfonce);
  padding: 10px 35px;
  border-radius: 20px;
  color: white !important;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 400;
  border: solid 2px var(--vertfonce);
  font-family: "Roboto", Regular;
  transition: background 300ms ease-in-out;
  text-decoration: none;
}
.button-contact:hover {
  background-position: 100%;
  color: var(--vertfonce) !important;
  background-color: transparent;
}

.button-exp {
  border-radius: 12px;
  padding: 2px 10px;
  border: solid 2px darkgrey;
  display: flex; /* Active Flexbox */
  align-items: center; /* Centre verticalement */
}

.button-exp:hover {
  border: solid 2px transparent;
}

.box-curves {
  background-color: white !important;
}

.scale-on-hover {
  transition: transform 0.3s ease-in-out; /* Durée et effet de la transition */
}

.scale-on-hover:hover {
  transform: scale(1.05); /* Grossir de 10% lors du survol */
}

.link-download {
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
}

.shadow {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1200px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.25rem;
  }
  h3 {
    font-size: 1.875rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  p {
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 2.25rem;
  }
  h2 {
    font-size: 1.875rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  p {
    font-size: 1rem;
  }
  .padding-resp {
    padding-left: 20px;
    padding-right: 20px;
  }
  .padding-resp-2 {
    padding-left: 20px;
  }
}

@media (max-width: 960px) {
  .mt100 {
    margin-top: 50px;
  }
  .row {
    margin: 0 !important;
  }
  .col-1 {
    padding: 0 !important;
  }
  .col-2 {
    padding: 0 !important;
  }
  .col-3 {
    padding: 0 !important;
  }
  .col-4 {
    padding: 0 !important;
  }
  .col-5 {
    padding: 0 !important;
  }
  .col-6 {
    padding: 0 !important;
  }
  .col-7 {
    padding: 0 !important;
  }
  .col-8 {
    padding: 0 !important;
  }
  .col-9 {
    padding: 0 !important;
  }
  .col-10 {
    padding: 0 !important;
  }
  .col-11 {
    padding: 0 !important;
  }

  .col-12 {
    padding: 0 !important;
  }

  .row-between-xl {
    display: block;
  }

  .button-contact {
    font-size: 16px;
    font-weight: 300;
    padding: 10px 20px;
  }
}
</style>
